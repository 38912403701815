<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tên giáo viên </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="name" rules="required" v-slot="{ errors }">
          <hnr-input
            :text="detailTeacher.name"
            v-model="detailTeacher.name"
            @input="onKeyUp"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Slug </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="slug" rules="required" v-slot="{ errors }">
          <hnr-input :text="slug" v-model="detailTeacher.slug"></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2">
      <v-col cols="2" class="pa-1">
        <span> Email </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="email" rules="required" v-slot="{ errors }">
          <hnr-input
            :text="detailTeacher.email"
            v-model="detailTeacher.email"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2">
      <v-col cols="2" class="pa-1">
        <span> Giới thiệu </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="content" rules="required" v-slot="{ errors }">
          <ck-content
          :content="detailTeacher.content"
          @getData="updateContent"
          v-model="detailTeacher.content"
        ></ck-content>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center pt-5">
      <v-col cols="2" class="pa-1">
        <span> Môn dạy </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="subject" rules="required" v-slot="{ errors }">
          <hnr-input
            :text="detailTeacher.subject"
            v-model="detailTeacher.subject"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2">
      <v-col cols="2" class="pa-1">
        <span> Phụ trách lớp </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="class" rules="required" v-slot="{ errors }">
          <hnr-input
            :text="detailTeacher.class"
            v-model="detailTeacher.class"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2">
      <v-col cols="2" class="pa-1">
        <span> Nơi công tác </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="address" rules="required" v-slot="{ errors }">
          <hnr-input
            :text="detailTeacher.address"
            v-model="detailTeacher.address"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2">
      <v-col cols="2" class="pa-1">
        <span> Số năm kinh nghiệm </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="convertNumberToString(detailTeacher.experience)"
            @input="update"
            v-model="detailTeacher.experience"
        ></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2">
      <v-col cols="2" class="pa-1">
        <span> Học vị </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="degree" rules="required" v-slot="{ errors }">
          <ck-content
          :content="detailTeacher.degree"
          @getData="updateDegree"
          v-model="detailTeacher.degree"
        ></ck-content>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tải ảnh </span>
      </v-col>
      <v-col cols="10" class="pa-1 d-flex align-center">
        <v-file-input
          class="pl-1"
          prepend-icon="mdi-camera"
          v-model="image"
          label="Tải ảnh"
          @change="onChangeFileUpload"
          @click:clear="clearImage"
        ></v-file-input>
        <v-col cols="2" class="pa-4">
          <v-img
            :src="imagePreviewUrl || replace_image"
            v-if="imagePreviewUrl || replace_image"
            contain
            class="image-container"
          ></v-img>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from "@/components/elements/hnr-input";
import mixin from "@/store/mixin";
import CkContent from "@/components/ck-content.vue";
import { SAVE_IMAGE } from "@/store/image.module";

export default {
  mixins: [mixin],
  props: {
    teacher: Object,
    isNew: { type: Boolean, default: false },
  },
  components: {
    CkContent,
    HnrInput,
  },
  data() {
    return {
      image: {},
      show: true,
      detailTeacher: {},
      imagePreviewUrl: null,
      name: "",
      slug: "",
    };
  },
  computed: {
    replace_image() {
      if (this.detailTeacher.img) {
        return this.detailTeacher.img;
      }
      return "";
    },
  },
  watch: {
    teacher: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailTeacher = newVal;
      },
    },
    detailTeacher: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.slug = newVal.slug;
      },
    },
  },
  methods: {
    onKeyUp(value) {
      this.detailTeacher.slug = this.sanitizeTitle(value);
    },
    updateContent(val) {
      this.detailTeacher.content = val;
    },
    updateDegree(val) {
      this.detailTeacher.degree = val;
    },
    onChangeFileUpload() {
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.image);
      this.$store.dispatch(SAVE_IMAGE, formData).then((data) => {
        if (data.status == 1 && data.url) {
          this.detailTeacher.img = data.url;
          this.imagePreviewUrl = data.url;
        }
      });
    },
    clearImage() {
      this.detailTeacher.img = "";
    },
    isRealValue(obj) {
      return obj && obj !== 'null' && obj !== 'undefined';
    },
    convertNumberToString(number) {
      if (this.isRealValue(number)) {
        return number.toString();
      }
      return "0";
    },
    update() {
      this.$emit("input");
    },
  },
};
</script>
