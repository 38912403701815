<template>
    <v-container>
      <ValidationObserver v-slot="{ handleSubmit }">
        <v-tabs v-model="tabIndex">
          <v-tab v-for="item in items" :key="item.id">{{ item.tab }}</v-tab>
        </v-tabs>
        <v-tabs-items class="tab-content" v-model="tabIndex">
          <v-tab-item active>
            <teacher-content
                class="tab-detail"
                :teacher="detailTeacher"
                :isNew="isCreate"
            ></teacher-content>
          </v-tab-item>
        </v-tabs-items>
        <v-row v-if="isCreate" class="align-center justify-center ma-0 pt-5">
          <v-btn color="primary" @click="handleSubmit(saveSubject)">Tạo mới giáo viên</v-btn>
        </v-row>
        <v-row v-else class="align-center justify-center ma-0 pt-5">
          <v-btn color="primary" @click="handleSubmit(updateSubject)">Cập nhật giáo viên</v-btn>
        </v-row>
      </ValidationObserver>
    </v-container>
  </template>
  
  <script>
  import {mapGetters, mapState} from "vuex";
  import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
  import {SET_LOADING} from "@/store/loading.module";
  import {SAVE_TEACHER, DETAIL_TEACHER, UPDATE_TEACHER} from "@/store/teacher.module";
  import {SUCCESS, ERROR} from "@/store/alert.module";
  import TeacherContent from '../../../components/teacher/TeacherContent.vue';
  
  export default {
    data() {
      return {
        id: "",
        isCreate: true,
        tabIndex: 0,
        items: [{id: 0, tab: "Thông tin giáo viên"}],
        headers: [
          {
            text: "id",
            value: "id",
          },
          {text: "Tên Chuyên mục", value: "name"},
        ],
        detailTeacher: {
          id: '',
          name: "",
          slug: "",
          content: "",
          subject: "",
          degree: "",
          img: "",
          class: "",
          experience: "",
          address: "",
          email: "",
         
        },
      };
    },
    components: {
      TeacherContent,
    },
    computed: {
      ...mapGetters({
        teacher: "detailTeacher",
      }),
    },
    watch: {
      teacher: {
        deep: true,
        immediate: true,
        handler(newVal) {
          this.detailTeacher = newVal;
        },
      },
    },
    created() {
      this.id = this.$route.query.id;
    },
    mounted() {
      if (this.id) {
        this.isCreate = false;
        this.$store.dispatch(SET_BREADCRUMB, [{title: "Sửa giáo viên"}]);
  
        this.$store.dispatch(SET_LOADING, true);
        this.$store
            .dispatch(DETAIL_TEACHER, this.id)
            .then(() => {
              this.$store.dispatch(SET_LOADING, false);
            })
            .catch(() => {
              this.$store.dispatch(SET_LOADING, false);
            });
      } else {
        this.isCreate = true;
        this.$store.dispatch(SET_BREADCRUMB, [{title: "Thêm giáo viên"}]);
      }
    },
    methods: {
      updateSubject() {
        let payload = {
          id: this.id,
          name: this.detailTeacher.name,
          slug: this.detailTeacher.slug ,
          content: this.detailTeacher.content ,
          subject: this.detailTeacher.subject ,
          degree: this.detailTeacher.degree ,
          img: this.detailTeacher.img ,
          class: this.detailTeacher.class ,
          experience: this.detailTeacher.experience ,
          address: this.detailTeacher.address ,
          email: this.detailTeacher.email ,
         
        };
        this.$store.dispatch(SET_LOADING, true);
        this.$store
            .dispatch(UPDATE_TEACHER, payload)
            .then((data) => {
              if (data.status) {
                this.$store.dispatch(SUCCESS, "Cập nhật giáo viên thành công!", {
                  root: true,
                });
                this.$router.push("/teacher");
                this.detailTeacher = {}
              } else {
                if (data.errorCode == 424) {
                  this.$store.dispatch(ERROR, data.message, {
                    root: true,
                  });
                } else {
                  this.$store.dispatch(ERROR, data.message, {
                    root: true,
                  });
                }
              }
              this.$store.dispatch(SET_LOADING, false);
            })
            .catch(() => {
              this.$store.dispatch(ERROR, "Cập nhật giáo viên thất bại!", {
                root: true,
              });
              this.$store.dispatch(SET_LOADING, false);
            });
      },
      saveSubject() {
        let payload = {
          name: this.detailTeacher.name,
          slug: this.detailTeacher.slug ,
          content: this.detailTeacher.content ,
          subject: this.detailTeacher.subject ,
          degree: this.detailTeacher.degree ,
          img: this.detailTeacher.img ,
          class: this.detailTeacher.class ,
          experience: this.detailTeacher.experience ,
          address: this.detailTeacher.address ,
          email: this.detailTeacher.email ,
        };
        this.$store.dispatch(SET_LOADING, true);
        this.$store
            .dispatch(SAVE_TEACHER, payload)
            .then((data) => {
              if (data.status) {
                this.$store.dispatch(SUCCESS, "Thêm giáo viên thành công!", {
                  root: true,
                });
                this.$router.push("/teacher");
              } else {
                this.$store.dispatch(ERROR, data.message, {
                  root: true,
                });
              }
              this.$store.dispatch(SET_LOADING, false);
            })
            .catch(() => {
              this.$store.dispatch(ERROR, "Thêm giáo viên thất bại!", {root: true});
              this.$store.dispatch(SET_LOADING, false);
            });
      },
    },
  };
  </script>
  
  <style lang="scss">
  .tab-detail {
    margin-top: 15px;
  }
  </style>
  